import React from "react";

const Process = () => {
  return (
    <div>
      <div className="dizme_tm_section" id="process">
        <div className="dizme_tm_process">
          <div className="container">
            <div className="list">
              <ul>
                <li className="wow fadeInUp" data-wow-duration="1s">
                  <div className="list_inner">
                    <div className="icon">
                      <span>
                        <img
                          className="brush"
                          src="img/brushes/process/1.png"
                          alt="brushes"
                        />
                        <img
                          className="svg"
                          src="img/svg/process/target.svg"
                          alt="image"
                        />
                      </span>
                    </div>
                    <div className="title">
                      <h3>Code Quality</h3>
                    </div>
                    <div className="text">
                      <p>
                        Delivering efficient, scalable, and maintainable code
                        for robust applications optimized across devices and
                        platforms
                      </p>
                    </div>
                  </div>
                </li>
                <li
                  className="wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="list_inner">
                    <div className="icon">
                      <span>
                        <img
                          className="brush"
                          src="img/brushes/process/2.png"
                          alt="process"
                        />
                        <img
                          className="svg"
                          src="img/svg/process/brush.svg"
                          alt="process"
                        />
                      </span>
                    </div>
                    <div className="title">
                      <h3>Pixel Precision</h3>
                    </div>
                    <div className="text">
                      <p>
                        Ensure design consistency across devices using
                        responsive and adaptive techniques for seamless user
                        experiences.
                      </p>
                    </div>
                  </div>
                </li>
                <li
                  className="wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <div className="list_inner">
                    <div className="icon">
                      <span>
                        <img
                          className="brush"
                          src="img/brushes/process/3.png"
                          alt="process"
                        />
                        <img
                          className="svg"
                          src="img/svg/process/energy.svg"
                          alt="energy"
                        />
                      </span>
                    </div>
                    <div className="title">
                      <h3>Creative Solutions</h3>
                    </div>
                    <div className="text">
                      <p>
                        Transforming innovative ideas into practical,
                        user-friendly applications with modern design and
                        functionality principles.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
