import axios from "axios";

// Fetch base URL, storeId, and API key from environment variables
const api_url = process.env.REACT_APP_API_URL;
const storeId = process.env.REACT_APP_STORE_ID;
const apiKey = process.env.REACT_APP_API_KEY;

// Create an Axios instance
const API = axios.create({
  baseURL: api_url,
  headers: {
    "x-api-key": apiKey,
    storeId: storeId,
  },
});

export default API;
