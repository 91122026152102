import React from "react";

const Testimonials = () => {
  return (
    <div>
      <div className="dizme_tm_section">
        <div className="dizme_tm_testimonials">
          <div className="dizme_tm_main_title" data-align="center">
            <span>Testimonials</span>
            <h3>What My Clients Say</h3>
            <p>
              Most clients praise how I implement Functional and modern design
              in their web applications for optimal desktop performance
            </p>
          </div>
          <div className="list_wrapper">
            <div className="total">
              <div className="in">
                <ul className="owl-carousel owl-theme">
                  <li>
                    <div className="icon">
                      <img
                        className="svg"
                        src="img/svg/testimonials/quote.svg"
                        alt="quote"
                      />
                    </div>
                    <div className="text">
                      <p>
                        delivered an outstanding eCommerce platform using the
                        MERN stack. His expertise in user authentication,
                        payment integration, and backend development was
                        impressive. Highly professional and timely, we highly
                        recommend him!
                      </p>
                    </div>
                    <div className="short">
                      <div className="image">
                        <div
                          className="main"
                          data-img-url="img/testimonials/4.jpg"
                        ></div>
                      </div>
                      <div className="detail">
                        <h3>Samuel Poksy</h3>
                        <span>Revel Studio</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img
                        className="svg"
                        src="img/svg/testimonials/quote.svg"
                        alt="quote"
                      />
                    </div>
                    <div className="text">
                      <p>
                        built an exceptional, responsive web app with real-time
                        data and role-based access control. Their MERN stack
                        skills and communication were outstanding. Highly
                        recommended!
                      </p>
                    </div>
                    <div className="short">
                      <div className="image">
                        <div
                          className="main"
                          data-img-url="img/testimonials/3.jpg"
                        ></div>
                      </div>
                      <div className="detail">
                        <h3>Lauren Kaufia</h3>
                        <span>Blossom Group</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img
                        className="svg"
                        src="img/svg/testimonials/quote.svg"
                        alt="quote"
                      />
                    </div>
                    <div className="text">
                      <p>
                        created a scalable, secure platform with flawless
                        third-party API integration. His MERN stack expertise
                        and attention to detail truly impressed us. Excellent
                        work!
                      </p>
                    </div>
                    <div className="short">
                      <div className="image">
                        <div
                          className="main"
                          data-img-url="img/testimonials/6.jpg"
                        ></div>
                      </div>
                      <div className="detail">
                        <h3>Kevin Rogers</h3>
                        <span>Project Manager</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
