import React from "react";

const Portfolio = () => {
  return (
    <div>
      <div className="dizme_tm_section" id="portfolio">
        <div className="dizme_tm_portfolio">
          <div className="container">
            <div className="dizme_tm_main_title" data-align="center">
              <span>Portfolio</span>
              <h3>My Amazing Works</h3>
              <p>
                Showcasing my creations! I specialize not only function but also
                precision to make sure websites look fantastic on desktops and
                beyond!
              </p>
            </div>
            <div className="portfolio_filter">
              <ul>
                <li>
                  <a href="#" className="current" data-filter="*">
                    All
                  </a>
                </li>
                <li>
                  <a href="#" data-filter=".ecommerce">
                    Ecommerce
                  </a>
                </li>
                <li>
                  <a href="#" data-filter=".dashboard">
                    Dashboard
                  </a>
                </li>
                <li>
                  <a href="#" data-filter=".booking">
                    Booking
                  </a>
                </li>
              </ul>
            </div>
            <div className="dizme_tm_portfolio_titles"></div>
            <div className="portfolio_list wow fadeInUp" data-wow-duration="1s">
              <ul className="gallery_zoom grid">
                <li className="dashboard grid-item">
                  <div className="inner">
                    <div
                      className="entry dizme_tm_portfolio_animation_wrap"
                      data-title="Home Page"
                      data-category="Dashboard"
                    >
                      <a className="zoom" href="img/portfolio/1.jpg">
                        <img src="img/thumbs/42-56.jpg" alt="thumbs" />
                        <div
                          className="main"
                          data-img-url="img/portfolio/1.jpg"
                        ></div>
                      </a>
                    </div>
                    <div className="mobile_title">
                      <h3>Mockup Shape</h3>
                      <span>Youtube</span>
                    </div>
                  </div>
                </li>
                <li className="booking grid-item">
                  <div className="inner">
                    <div
                      className="entry dizme_tm_portfolio_animation_wrap"
                      data-title="Home Page"
                      data-category="Booking"
                    >
                      <a className="zoom" href="img/portfolio/2.jpg">
                        <img src="img/thumbs/42-56.jpg" alt="thumbs" />
                        <div
                          className="main"
                          data-img-url="img/portfolio/2.jpg"
                        ></div>
                      </a>
                    </div>
                    <div className="mobile_title">
                      <h3>Home Page</h3>
                      <span>Booking</span>
                    </div>
                  </div>
                </li>
                <li className="ecommerce grid-item">
                  <div className="inner">
                    <div
                      className="entry dizme_tm_portfolio_animation_wrap"
                      data-title="Shop Page"
                      data-category="Ecommerce"
                    >
                      <a className="zoom" href="img/portfolio/3.jpg">
                        <img src="img/thumbs/42-56.jpg" alt="thumbs" />
                        <div
                          className="main"
                          data-img-url="img/portfolio/3.jpg"
                        ></div>
                      </a>
                    </div>
                    <div className="mobile_title">
                      <h3>Magic Art</h3>
                      <span>Soundcloud</span>
                    </div>
                  </div>
                </li>
                <li className="booking grid-item">
                  <div className="inner">
                    <div
                      className="entry dizme_tm_portfolio_animation_wrap"
                      data-title="Search Page"
                      data-category="Booking"
                    >
                      <a className="zoom" href="img/portfolio/5.jpg">
                        <img src="img/thumbs/42-56.jpg" alt="thumbs" />
                        <div
                          className="main"
                          data-img-url="img/portfolio/5.jpg"
                        ></div>
                      </a>
                    </div>
                    <div className="mobile_title">
                      <h3>Search Page</h3>
                      <span>Booking</span>
                    </div>
                  </div>
                </li>
                <li className="ecommerce grid-item">
                  <div className="inner">
                    <div
                      className="entry dizme_tm_portfolio_animation_wrap"
                      data-title="Home Page"
                      data-category="Ecommerce"
                    >
                      <a className="zoom" href="img/portfolio/4.jpg">
                        <img src="img/thumbs/42-56.jpg" alt="thumbs" />
                        <div
                          className="main"
                          data-img-url="img/portfolio/4.jpg"
                        ></div>
                      </a>
                    </div>
                    <div className="mobile_title">
                      <h3>Home Page</h3>
                      <span>Ecommerce</span>
                    </div>
                  </div>
                </li>
                <li className="dashboard grid-item">
                  <div className="inner">
                    <div
                      className="entry dizme_tm_portfolio_animation_wrap"
                      data-title="Product Page"
                      data-category="Dashboard"
                    >
                      <a className="zoom" href="img/portfolio/6.jpg">
                        <img src="img/thumbs/42-56.jpg" alt="thumbs" />
                        <div
                          className="main"
                          data-img-url="img/portfolio/6.jpg"
                        ></div>
                      </a>
                    </div>
                    <div className="mobile_title">
                      <h3>Mockup Shape</h3>
                      <span>Youtube</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
