import React from "react";

const About = () => {
  return (
    <div>
      <div className="dizme_tm_section" id="about">
        <div className="dizme_tm_about">
          <div className="container">
            <div className="wrapper">
              <div className="left">
                <div className="image">
                  <img src="img/about/3.png" alt="about" />
                  <div className="numbers year">
                    <div className="wrapper">
                      <h3>
                        <span
                          className="dizme_tm_counter"
                          data-from="0"
                          data-to="5"
                          data-speed="2000"
                        >
                          0
                        </span>
                      </h3>
                      <span className="name">
                        Years of
                        <br />
                        Success
                      </span>
                    </div>
                  </div>
                  <div className="numbers project">
                    <div className="wrapper">
                      <h3>
                        <span
                          className="dizme_tm_counter"
                          data-from="0"
                          data-to="68"
                          data-speed="2000"
                        >
                          0
                        </span>
                      </h3>
                      <span className="name">
                        Total
                        <br />
                        Projects
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="title wow fadeInUp" data-wow-duration="1s">
                  <span>I'm a MERN Stack Developer</span>
                  <h3>I Can Build Anything You Need</h3>
                </div>
                <div className="text wow fadeInUp" data-wow-duration="1s">
                  <p>
                    Hey there! I'm a MERN Stack web wizard with 5 years of
                    experience, turning ideas into code and making projects a
                    success. I love every part of the process—from brainstorming
                    to building. Let's create something awesome!
                  </p>
                </div>
                <div
                  className="dizme_tm_button wow fadeInUp"
                  data-wow-duration="1s"
                >
                  <a className="anchor" href="#contact">
                    <span>Hire Me</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
