import { useState } from "react";

const Hero = () => {
  const [style, setStyle] = useState({
    transform: "scale(1) rotate(0deg)", // Initial state
    boxShadow: "none", // Initial box shadow
    transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition
  });
  return (
    <div>
      <div className="dizme_tm_section" id="home">
        <div className="dizme_tm_hero">
          <div className="container">
            <div className="content">
              <div className="details">
                <div className="hello">
                  <h3>Hello, I'm</h3>
                </div>
                <div className="name">
                  <h3>Milton Mahmud</h3>
                </div>
                <div className="job">
                  <p style={{ fontWeight: "200" }}>
                    Crafting SPA Web Solutions
                  </p>
                </div>
                <div className="text">
                  <p>
                    I'm a passionate MERN Stack developer from Bangladesh,
                    turning coffee and code into digital magic daily!
                  </p>
                </div>
                <div className="button">
                  <div className="dizme_tm_button">
                    <a className="anchor" href="#about">
                      <span>About Me</span>
                    </a>
                  </div>
                </div>
                <img
                  src="img/mern.png"
                  alt="mern"
                  style={{
                    width: "306px",
                    height: "auto",
                    objectFit: "cover",
                    marginTop: "40px",
                    marginLeft: "-10px",
                  }}
                />
              </div>
              <div className="avatar">
                <div
                  className="image"
                  style={{
                    overflow: "hidden",
                    width: "300px",
                    margin: "0 auto",
                  }}
                >
                  <img
                    src="img/slider/portfolio.jpg"
                    alt="Description"
                    style={style}
                    onMouseOver={() => {
                      setStyle((prevStyle) => ({
                        ...prevStyle, // Preserve the existing transition property
                        transform: "scale(1.1) rotate(-3deg)", // Scale and rotate effect
                        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)", // Apply box shadow
                      }));
                    }}
                    onMouseOut={() => {
                      setStyle((prevStyle) => ({
                        ...prevStyle, // Preserve the existing transition property
                        transform: "scale(1) rotate(0deg)", // Reset scale and rotation
                        boxShadow: "none", // Remove box shadow
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
