import "./App.css";
import About from "./components/About";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Mobile from "./components/Mobile";
import Portfolio from "./components/Portfolio";
import Process from "./components/Process";
import Service from "./components/Service";
import Skills from "./components/Skills";
import Testimonials from "./components/Testimonials";
import { Toaster, toast } from "react-hot-toast";

function App() {
  return (
    <div>
      <Toaster />
      <div className="dizme_tm_all_wrap" data-magic-cursor="show">
        <Mobile />
        <Header />
        <Hero />
        <Process />
        <About />
        <Portfolio />
        <Skills />
        <Service />
        <Testimonials />
        <Contact />
        <div className="progressbar">
          <a href="#">
            <span className="text">To Top</span>
          </a>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
}

export default App;
