import React from "react";

const Service = () => {
  return (
    <div>
      <div className="dizme_tm_section" id="service">
        <div className="dizme_tm_services">
          <div className="container">
            <div className="dizme_tm_main_title" data-align="center">
              <span>Services</span>
              <h3>What I Serve to my Clients</h3>
              <p>
                I build responsive and adaptive web applications that function
                seamlessly on desktops, enhancing user engagement
              </p>
            </div>
            <div className="service_list">
              <ul>
                <li className="wow fadeInLeft" data-wow-duration="1s">
                  <div className="list_inner tilt-effect">
                    <span className="icon">
                      <img
                        className="svg"
                        src="img/svg/service/anchor.svg"
                        alt="anchor"
                      />
                      <img
                        className="back"
                        src="img/brushes/service/1.png"
                        alt="service"
                      />
                    </span>
                    <div className="title">
                      <h3>Full-Stack Development</h3>
                    </div>
                    <div className="text">
                      <p>
                        Building scalable web applications using MongoDB,
                        Express, React, and Node.js to optimize performance and
                        enhance user engagement.
                      </p>
                    </div>
                    <a className="dizme_tm_full_link" href="#"></a>
                    <img
                      className="popup_service_image"
                      src="img/service/1.jpg"
                      alt="service"
                    />
                    <div className="service_hidden_details">
                      <div className="service_popup_informations">
                        <div className="descriptions">
                          <p>
                            Dizme is a leading web design agency with an
                            award-winning design team that creates innovative,
                            effective websites that capture your brand, improve
                            your conversion rates, and maximize your revenue to
                            help grow your business and achieve your goals.
                          </p>
                          <p>
                            In today’s digital world, your website is the first
                            interaction consumers have with your business.
                            That's why almost 95 percent of a user’s first
                            impression relates to web design. It’s also why web
                            design services can have an immense impact on your
                            company’s bottom line.
                          </p>
                          <p>
                            That’s why more companies are not only reevaluating
                            their website’s design but also partnering with
                            Kura, the web design agency that’s driven more than
                            $2.4 billion in revenue for its clients. With over
                            50 web design awards under our belt, we're confident
                            we can design a custom website that drives sales for
                            your unique business.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  className="wow fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="list_inner tilt-effect">
                    <span className="icon">
                      <img
                        className="svg"
                        src="img/svg/service/physics.svg"
                        alt="physics"
                      />
                      <img
                        className="back"
                        src="img/brushes/service/2.png"
                        alt="service"
                      />
                    </span>
                    <div className="title">
                      <h3>E-Commerce Solutions</h3>
                    </div>
                    <div className="text">
                      <p>
                        Developing robust eCommerce platforms with user-friendly
                        features, payment integration, and seamless checkout
                        experiences to maximize sales.
                      </p>
                    </div>
                    <a className="dizme_tm_full_link" href="#"></a>
                    <img
                      className="popup_service_image"
                      src="img/service/2.jpg"
                      alt="service"
                    />
                    <div className="service_hidden_details">
                      <div className="service_popup_informations">
                        <div className="descriptions">
                          <p>
                            Dizme is a leading web design agency with an
                            award-winning design team that creates innovative,
                            effective websites that capture your brand, improve
                            your conversion rates, and maximize your revenue to
                            help grow your business and achieve your goals.
                          </p>
                          <p>
                            In today’s digital world, your website is the first
                            interaction consumers have with your business.
                            That's why almost 95 percent of a user’s first
                            impression relates to web design. It’s also why web
                            design services can have an immense impact on your
                            company’s bottom line.
                          </p>
                          <p>
                            That’s why more companies are not only reevaluating
                            their website’s design but also partnering with
                            Kura, the web design agency that’s driven more than
                            $2.4 billion in revenue for its clients. With over
                            50 web design awards under our belt, we're confident
                            we can design a custom website that drives sales for
                            your unique business.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="wow fadeInLeft" data-wow-duration="1s">
                  <div className="list_inner tilt-effect">
                    <span className="icon">
                      <img
                        className="svg"
                        src="img/svg/service/contact.svg"
                        alt="contact"
                      />
                      <img
                        className="back"
                        src="img/brushes/service/3.png"
                        alt="service"
                      />
                    </span>
                    <div className="title">
                      <h3>API Development</h3>
                    </div>
                    <div className="text">
                      <p>
                        Creating secure RESTful APIs for efficient data exchange
                        between frontend and backend systems, ensuring high
                        performance.
                      </p>
                    </div>
                    <a className="dizme_tm_full_link" href="#"></a>
                    <img
                      className="popup_service_image"
                      src="img/service/3.jpg"
                      alt="service"
                    />
                    <div className="service_hidden_details">
                      <div className="service_popup_informations">
                        <div className="descriptions">
                          <p>
                            Dizme is a leading web design agency with an
                            award-winning design team that creates innovative,
                            effective websites that capture your brand, improve
                            your conversion rates, and maximize your revenue to
                            help grow your business and achieve your goals.
                          </p>
                          <p>
                            In today’s digital world, your website is the first
                            interaction consumers have with your business.
                            That's why almost 95 percent of a user’s first
                            impression relates to web design. It’s also why web
                            design services can have an immense impact on your
                            company’s bottom line.
                          </p>
                          <p>
                            That’s why more companies are not only reevaluating
                            their website’s design but also partnering with
                            Kura, the web design agency that’s driven more than
                            $2.4 billion in revenue for its clients. With over
                            50 web design awards under our belt, we're confident
                            we can design a custom website that drives sales for
                            your unique business.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  className="wow fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="list_inner tilt-effect">
                    <span className="icon">
                      <img
                        className="svg"
                        src="img/svg/service/web.svg"
                        alt="web"
                      />
                      <img
                        className="back"
                        src="img/brushes/service/4.png"
                        alt="service"
                      />
                    </span>
                    <div className="title">
                      <h3>SPA Solutions</h3>
                    </div>
                    <div className="text">
                      <p>
                        Crafting single-page applications that enhance user
                        engagement and deliver superior performance on desktop
                        devices using modern technologies.
                      </p>
                    </div>
                    <a className="dizme_tm_full_link" href="#"></a>
                    <img
                      className="popup_service_image"
                      src="img/service/4.jpg"
                      alt="service"
                    />
                    <div className="service_hidden_details">
                      <div className="service_popup_informations">
                        <div className="descriptions">
                          <p>
                            Dizme is a leading web design agency with an
                            award-winning design team that creates innovative,
                            effective websites that capture your brand, improve
                            your conversion rates, and maximize your revenue to
                            help grow your business and achieve your goals.
                          </p>
                          <p>
                            In today’s digital world, your website is the first
                            interaction consumers have with your business.
                            That's why almost 95 percent of a user’s first
                            impression relates to web design. It’s also why web
                            design services can have an immense impact on your
                            company’s bottom line.
                          </p>
                          <p>
                            That’s why more companies are not only reevaluating
                            their website’s design but also partnering with
                            Kura, the web design agency that’s driven more than
                            $2.4 billion in revenue for its clients. With over
                            50 web design awards under our belt, we're confident
                            we can design a custom website that drives sales for
                            your unique business.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
