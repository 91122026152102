import React from "react";

const Skills = () => {
  return (
    <div>
      <div className="dizme_tm_section">
        <div className="dizme_tm_skills">
          <div className="container">
            <div className="wrapper">
              <div className="left">
                <div
                  className="dizme_tm_main_title wow fadeInUp"
                  data-wow-duration="1s"
                  data-align="left"
                >
                  <span>Design is Life</span>
                  <h3>I regularly develop myself to stay updated.</h3>
                  <p>
                    Below is my expertise level, showcasing my skills in MERN
                    Stack development and design.
                  </p>
                </div>
                <div
                  className="dodo_progress wow fadeInUp"
                  data-wow-duration="1s"
                >
                  <div
                    className="progress_inner"
                    data-value="85"
                    data-color="#f75023"
                  >
                    <span>
                      <span className="label">React</span>
                      <span className="number">85%</span>
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div className="bar_in"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="progress_inner"
                    data-value="90"
                    data-color="#1cbe59"
                  >
                    <span>
                      <span className="label">Node</span>
                      <span className="number">90%</span>
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div className="bar_in"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="progress_inner"
                    data-value="80"
                    data-color="#8067f0"
                  >
                    <span>
                      <span className="label">Express</span>
                      <span className="number">80%</span>
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div className="bar_in"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="progress_inner"
                    data-value="85"
                    data-color="#2dcdd5"
                  >
                    <span>
                      <span className="label">Mongo</span>
                      <span className="number">85%</span>
                    </span>
                    <div className="background">
                      <div className="bar">
                        <div className="bar_in"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <img src="img/skills/2.png" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
