import React, { useEffect, useState } from "react";
import API from "../API";
import { toast } from "react-hot-toast";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send the form data to the backend
      const response = await API.post("/messages", formData);
      // Optionally, reset the form after successful submission
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });

      toast.success("Message sent Successfully!");
    } catch (error) {
      console.error("Error sending message:", error);
      // Display an error message to the user
      alert("Failed to send message. Please try again later.");
    }
  };
  return (
    <div>
      <div className="dizme_tm_section" id="contact">
        <div className="dizme_tm_contact">
          <div className="container">
            <div className="dizme_tm_main_title" data-align="center">
              <span>Contact Me</span>
              <h3>I Want To Hear From You!</h3>
              <p>
                Fill out the form below to get in touch, or call me Saturday to
                Thursday, 9:00 a.m. to 8:00 p.m. ET
              </p>
            </div>
            <div className="contact_inner">
              <div className="left wow fadeInLeft" data-wow-duration="1s">
                <ul>
                  <li>
                    <div className="list_inner">
                      <div className="icon orangeBackground">
                        <i className="icon-location orangeText"></i>
                      </div>
                      <div className="short">
                        <h3>Address</h3>
                        <span>
                          79 G,A Road, Bandar, Narayanganj, Bangladesh
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <div className="icon greenBackground">
                        <i className="icon-mail-1 greenText"></i>
                      </div>
                      <div className="short">
                        <h3>Email</h3>
                        <span>
                          <a href="#">
                            <span>[milton.mahmud@yahoo.com]</span>
                          </a>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <div className="icon purpleBackground">
                        <i className="icon-phone purpleText"></i>
                      </div>
                      <div className="short">
                        <h3>Phone</h3>
                        <span>+880 1671713131</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="right wow fadeInRight" data-wow-duration="1s">
                <div className="fields">
                  <form onSubmit={handleSubmit}>
                    <div
                      className="returnmessage"
                      data-success="Your message has been received, We will contact you soon."
                    ></div>
                    <div className="empty_notice">
                      <span>Please Fill Required Fields</span>
                    </div>
                    <div className="input_list">
                      <ul>
                        <li>
                          <input
                            id="name"
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleInputChange}
                          />
                        </li>
                        <li>
                          <input
                            id="email"
                            name="email"
                            type="text"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={handleInputChange}
                          />
                        </li>
                        <li>
                          <input
                            id="phone"
                            type="number"
                            name="phone"
                            placeholder="Your Phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                          />
                        </li>
                        <li>
                          <input
                            id="subject"
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            value={formData.subject}
                            onChange={handleInputChange}
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="message_area">
                      <textarea
                        id="message"
                        placeholder="Write your message here"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>

                    <div className="button">
                      <button
                        type="submit"
                        title="Submit Your Message!"
                        className="dizme_tm_button tm"
                        id="submitButton"
                        name="submit"
                        value="Submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
